import styled from 'styled-components';

export const AlertBox = styled.div`
  position: relative;
  width: auto;
  min-width: 90px;
  transition: 0.5s;
  .alert-class {
    > * {
      border-radius: 4px;
      position: relative;
      border-bottom: 2px solid #cf1919;
      /* transition: border 0.1s; */
    }
    /* p {
      position: absolute;
      right: 0;
      border: none;
      color: #cf1919;
      transition: color 0.2s;
    } */
  }
  .css-16kwnwk {
    border: none !important;
    border-color: inherit !important;
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none !important;
    &:focus {
      border: none !important;
      border-color: inherit !important;
      outline: none !important;
      -webkit-box-shadow: none;
      box-shadow: none !important;
    }
  }
  .css-13fq05f {
    /* border: none !important;
    border-color: inherit !important;
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none !important; */
    &:focus {
      border: none !important;
      border-color: none !important;
      outline: none !important;
      -webkit-box-shadow: none;
      box-shadow: none !important;
    }
  }
`;
